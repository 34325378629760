<template>
  <div class="bigDiv">
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2025/01/08/173631714621378363.png"
      alt=""
      style="margin-bottom: 120px;"
      class="img"
    />

    <div class="btoDiv" @click="show = true">
      <div class="top">
        <div class="left">
          <div class="a">仅剩{{ proper }}个名额</div>
          <div class="b">
            请在<van-count-down
              :time="times"
              :millisecond="true"
              style="margin: 0 4px;"
            >
              <template #default="timeData">
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.milliseconds }}</span>
              </template>
            </van-count-down>
            <!-- 请在 <van-count-down millisecond :time="times" format="mm:ss:SS" /> -->
            内完成领取
          </div>
        </div>
        <div class="right">
          <div>立即领取</div>
        </div>
      </div>
      <div class="bto">
        <van-checkbox v-model="checked" checked-color="#ff6933">
          我已阅读并同意
          <span style="color: #e93323;"
            >《个人信息授权与保护声明》</span
          ></van-checkbox
        >
      </div>
    </div>
    <van-action-sheet v-model="show">
      <div class="content">
        <div class="left">
          <div class="titred">仅剩11名</div>
          <div class="tittext">剩余领取名额</div>
        </div>
        <div>
          <div class="titred">
            <span>{{ formattedMinutes }}</span
            >:<span>{{ formattedSeconds }}</span
            >:<span>{{ formattedMilliseconds }}</span>
          </div>
          <div class="tittext">剩余领取时间</div>
        </div>
      </div>

      <div class="form">
        <div class="formTit">{{ type.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type.daan"
            :key="res.value"
            @click="question[0].value = res.name"
            :class="{ formItemActive: res.name === question[0].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
      <div class="form">
        <div class="formTit">{{ type1.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type1.daan"
            :key="res.value"
            @click="question[1].value = res.name"
            :class="{ formItemActive: res.name === question[1].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
      <div class="form">
        <div class="formTit">{{ type2.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type2.daan"
            :key="res.value"
            @click="question[3].value = res.name"
            :class="{ formItemActive: res.name === question[3].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
      <div class="form">
        <div class="formTit">
          您的手机号<span style="color: #d23f2b;">(确认领课的唯一凭证 )</span>
        </div>
        <div class="wenti">
          <van-field
            v-model="question[2].mobile"
            center
            clearable
            placeholder="为了确保正常上课，请填写手机号"
          >
            <!-- <template #button>
              <div
                style="color: #d23f2b;"
                type="primary"
                @click="verifyCode"
                v-if="codeFlag && question[2].mobile.length === 11"
              >
                发送验证码
              </div>
              <div
                v-else-if="!codeFlag && question[2].mobile.length === 11"
                style="color: #999;"
              >
                {{ codeTime }}秒后重新发送
              </div>
            </template> -->
          </van-field>
        </div>

        <!-- <div class="formTit" v-if="question[2].mobile.length === 11">
          验证码:
        </div>
        <div class="wenti" v-if="question[2].mobile.length === 11">
          <van-field
            v-model="query.mobile_code"
            center
            clearable
            placeholder="请输入验证码"
          >
          </van-field>
        </div> -->
      </div>
      <div class="btodivsa" @click="throttleSubmit">
        <img
          src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173390542766056760.jpg"
          alt=""
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getVerify, getTgUnionid, getTgUnifyPayH5 } from "../../api/components";
import { getWxCsad } from "../../api/360AD.js";
import { Toast } from "vant";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      proper: 99,
      codeFlag: true,
      checked: true,
      lastClickTime: 0,
      times: 30 * 60 * 1000,
      lastClickTimeA: 0,
      value: "",
      show: false,
      query: {
        mobile: "",
        mobile_code: "",
      },
      type: {
        name: "您的性别是?",
        daan: [
          { name: "男", value: "1" },
          { name: "女", value: "2" },
        ],
      },
      type1: {
        name: "您的年龄是?",
        daan: [
          { name: "41-45岁", value: "1" },
          { name: "46-50岁", value: "2" },
          { name: "51-55岁", value: "3" },
          { name: "55-60岁", value: "4" },
          { name: "61-65岁", value: "5" },
          { name: "66岁以上", value: "6" },
        ],
      },
      type2: {
        name: "老师养生直播课晚上6点半开课,您有时间听课吗 ?",
        daan: [
          { name: "有", value: "1" },
          { name: "无", value: "2" },
        ],
      },
      question: [
        {
          key: "您的性别是?",
          value: "",
        },
        {
          key: "您的年龄是?",
          value: "",
        },
        {
          mobile: "",
        },
        {
          key: "老师养生直播课晚上6点半开课，您有时间听课吗?",
          value: "",
        },
      ],
      memberInfo: {
        open_id: "",
      },
      code: "",
      time: 60,
      flag: true,
      ruleForm: {
        land_link: "", //落地页链接
      },
      duration: 1800000,
      remainingTime: 1800000,
      timer: null,

      displayedItems: [],
      currentIndex: 0,
      codeTime: 60,
    };
  },
  created() {
    const code = this.$route.query.code;
    this.query.mobile = this.$route.query.mobile;
    this.code = this.$route.query.mobilecode;
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("mobile");
    currentUrl.searchParams.delete("mobilecode");
    currentUrl.searchParams.delete("code");
    currentUrl.searchParams.delete("state");
    this.ruleForm.land_link = currentUrl.href;
    if (code) {
      // this.getTgUnionid();
      this.show = this.$route.query.show;
    }
  },
  computed: {
    formattedMinutes() {
      const minutes = Math.floor(
        (this.remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      return String(minutes).padStart(2, "0");
    },
    formattedSeconds() {
      const seconds = Math.floor((this.remainingTime % (1000 * 60)) / 1000);
      return String(seconds).padStart(2, "0");
    },
    formattedMilliseconds() {
      const milliseconds = Math.floor((this.remainingTime % 1000) / 10);
      return String(milliseconds).padStart(2, "0");
    },
  },
  methods: {
    // 获取验证码
    async verifyCode() {
      const res = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (res.test(this.question[2].mobile)) {
        this.codeFlag = false;
        this.codetimer();
        const data = await getVerify({
          mobile: this.question[2].mobile,
          sms_code: "SMS_474895679",
        });
        this.startCountdown();

        console.log(data);
      } else {
        Toast.fail("请输入正确的手机号!");
      }
    },

    codetimer() {
      const tmer = setInterval(() => {
        this.codeTime = this.codeTime - 1;
        if (this.codeTime <= 0) {
          this.codeFlag = true;
          this.codeTime = 60;
          clearInterval(tmer);
        }
      }, 1000);
    },
    initItems() {
      this.displayedItems = [this.items[0]];
    },
    startScroll() {
      this.scrollTimer = setInterval(this.scrollItems, 3000);
    },
    scrollItems() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      const nextItem = this.items[this.currentIndex];

      // 将新元素添加到列表中
      this.displayedItems.push(nextItem);

      // 在动画完成后移除旧元素
      // setTimeout(() => {
      this.displayedItems.shift();
      // }, 200); // 动画时间与 CSS transition 时间匹配
    },
    updateCountdown() {
      const now = Date.now();
      this.remainingTime = this.endTime - now;

      if (this.remainingTime <= 0) {
        this.remainingTime = 0;
        clearInterval(this.timer);
      }
    },
    formatter(value) {
      // 仅保留输入的数字
      return value.replace(/\D/g, "");
    },
    async getVerify() {
      const res = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (res.test(this.query.mobile)) {
        if (this.flag) {
          this.flag = false;
          const a = {
            mobile: this.query.mobile,
            sms_code: "SMS_474895679",
          };
          const data = await getVerify(a);
          this.startCountdown();
          console.log(data);
        }
      } else {
        Toast.fail("请输入正确的手机号!");
      }
    },
    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // 清除之前的定时器
      }
      this.flag = false; // 重置结束标志
      this.time = 60; // 重置时间
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.flag = true; // 设置结束标志
          this.time = 60; // 重新设置时间
          return;
        }
        this.time -= 1; // 减少时间
      }, 1000);
    },
    timeore() {
      const times = setInterval(() => {
        if (this.time === 0) {
          this.flag = true;
          this.time = 60;

          return;
        }
        this.time = this.time - 1;
        this.timeore();
      }, 1000);
    },
    throttleSubmit() {
      if (!this.checked) {
        return Toast.fail("请先勾选用户协议");
      }
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick();
      console.log(this.question);
      console.log(this.query, "wwwww");
    },
    async wxClick() {
      if (!this.checked) {
        return Toast.fail("请先勾选用户协议");
      }
      console.log(this.question);
      if (this.question[0].value === "") {
        return Toast.fail("请选择您的基本信息后再进行提交~");
      }
      if (this.question[1].value === "") {
        return Toast.fail("请选择您的基本信息后再进行提交~");
      }
      if (this.question[3].value === "") {
        return Toast.fail("请选择您的基本信息后再进行提交~");
      }
      // }
      const test = /^\d{11}$/;
      console.log(test.test(this.question[2].mobile));
      if (!test.test(this.question[2].mobile)) {
        return Toast.fail("请输入正确的手机号!");
      }
      // if (this.query.mobile_code == "") {
      //   return Toast.fail("请输入验证码!");
      // }
      const query = {
        question: this.question,
        land_link: this.ruleForm.land_link,
        // mobile: this.question[2].mobile,
        // mobile_code: this.query.mobile_code,
      };
      const { data } = await getWxCsad(query);
      window.location.href = data.data;
    },
    // async wxClick() {
    //   // const res = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
    //   // if (res.test(this.query.mobile) && this.code !== '') {
    //   //   const qyeru = {
    //   //     mobile: this.query.mobile,
    //   //     mobile_code: this.code,
    //   //     land_link: this.ruleForm.land_link
    //   //   }

    //   if (this.is_weixn()) {
    //     this.getCode();
    //   } else {
    //     this.getTgUnifyPayH5();
    //   }

    //   // } else {
    //   //   window.scrollTo(0, 0)
    //   //   Toast.fail('请输入正确的手机号/验证码!')
    //   // }
    // },

    is_weixn() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return true;
      } else {
        return false;
      }
    },
    async getCode() {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手動授權
      let info = {
        appid: "wx5a913d4bf9912989",
      };

      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      const redirectUrl = basAAA;
      const { code, mobile, mobilecode, ...rest } = this.$route.query;

      // 将剩余字段转换为查询字符串
      const queryString = new URLSearchParams(rest).toString();

      console.log(123, queryString);
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/txyangshengA?${queryString}&show=true`
        )}&response_type=code&scope=${scope}#wechat_redirect`
      );
      // }
    },

    uniIdSubmit() {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTimeA < 1500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTimeA = currentTime; // 更新上次点击时间

      this.getTgUnionid();
    },
    async getTgUnionid() {
      // for (let i = 0; i < this.question.length; i++) {
      console.log(this.question);
      if (this.question[0].value === "") {
        return Toast.fail("请选择您的基本信息后再进行提交~");
      }
      if (this.question[1].value === "") {
        return Toast.fail("请选择您的基本信息后再进行提交~");
      }
      // }
      const test = /^\d{11}$/;
      console.log(test.test(this.question[2].mobile));
      if (!test.test(this.question[2].mobile)) {
        return Toast.fail("请输入正确的手机号!");
      }
      const codes = sessionStorage.getItem("queryCode");
      if (codes) {
        this.memberInfo = JSON.parse(sessionStorage.getItem("memberInfo"));

        return this.getTgUnifyPayH5();
      }
      try {
        const query = {
          code: this.$route.query.code,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          appid: "wx5a913d4bf9912989",
          land_link: this.ruleForm.land_link,
        };
        const { data } = await getTgUnionid(query);
        this.memberInfo = data.data;
        console.log(data.data);
        console.log(this.memberInfo, "88");
        this.$toast.loading("正在加载,请稍等~");
        sessionStorage.setItem("queryCode", this.$route.query.code);
        sessionStorage.setItem("memberInfo", JSON.stringify(this.memberInfo));

        this.getTgUnifyPayH5();
      } catch (error) {
        console.log(error);
      }
    },

    async getTgUnifyPayH5() {
      try {
        const query = {
          appid: "wx5a913d4bf9912989",
          open_id: this.memberInfo.openid,
          price: this.$route.query.price || 0,
          unionid: this.memberInfo.unionid,
          headimgurl: this.memberInfo.headimgurl,
          wechat_name: this.memberInfo.nickname,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
          question: this.question,
        };
        const res = await getTgUnifyPayH5(query);
        console.log(res, "8897879");
        this.wechatPay(res);
      } catch (error) {
        console.log(error, "我是错误");
        // this.getCode()
      }
    },

    wechatPay(res) {
      if (this.is_weixn()) {
        const {
          appId,
          timestamp,
          nonceStr,
          signature,
          paySign,
          signType,
          trade_no,
        } = res.data.data.data;

        wx.config({
          debug: false,
          appId: "wx5a913d4bf9912989",
          timestamp,
          nonceStr,
          signature,
          jsApiList: ["chooseWXPay"],
        });
        this.$toast.clear();

        wx.chooseWXPay({
          timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: (res) => {
            console.log("成功");
            // this.isPay = true
            // this.ISshow = true
            // window.location.href = 'https://work.weixin.qq.com/ca/cawcdee1a6bfffe85c?customer_channel=zmwk_BRYtC_c_{}_p_{}'

            // 支付成功后的回调函数
            this.$router.replace({
              path: "/txTgPayOk",
              query: {
                trade_no: trade_no,
              },
            });
          },
        });
      } else {
        console.log(res.data.data.data);
        window.location.href = res.data.data.data;
      }
    },
    proTimes() {
      const times = setInterval(() => {
        if (this.proper > 0) {
          const randomNumber = Math.floor(Math.random() * 3) + 1;
          this.proper = Math.max(this.proper - randomNumber, 0);
        } else {
          clearInterval(times);
        }
      }, 7000);
    },
  },
  mounted() {
    this.proTimes();
    this.initItems();
    this.startScroll();
    this.endTime = Date.now() + this.duration;
    this.updateCountdown();
    this.timer = setInterval(this.updateCountdown, 10);
  },
  beforeDestroy() {
    clearInterval(this.scrollTimer);
    clearInterval(this.timer);
  },
  watch: {},

  filters: {},
};
</script>

<style scoped lang="less">
.scroll-item {
  margin-top: 20px;
  margin-left: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.name {
  font-size: 14px;
  width: 100px;
  text-align-last: left;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.bigDiv {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: #ecf7e4;
  flex: 0 0 auto; /* 不缩放，保持原始大小 */
  .form {
    padding: 0 30px;
    .formTit {
      width: 320px;
      font-size: 18px;
      font-weight: 700;
      margin: 10px 0;
    }
    .wenti {
      display: flex;
      flex-wrap: wrap;
      .formItem {
        width: 130px;
        height: 40px;
        text-align: center;
        font-size: 15px;
        line-height: 40px;
        background-color: #f8f8f8;
        margin: 3px 10px;
        border-radius: 10px;
      }
      .formItemActive {
        background-color: #d23f2b;
        color: #fff;
      }
    }
  }
  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .xhf {
    width: 100%;
    height: 100%;
    overflow: hidden;

    height: 100px;
    position: fixed;
    bottom: 69px;
    background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/08/29/172492057064514357.png");
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    align-items: center;
    .right {
      position: relative;
      top: 10px;
      left: 10px;
      font-size: 14px;
      span {
        color: red;
        font-weight: 700;
      }
    }
  }
  .btoDiv {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    height: 100px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 0 15px;
    justify-content: space-between;
    .top {
      display: flex;
      height: 70px;
      align-items: center;
      .left {
        text-align: left;
        flex: 1;
        .a {
          color: #ff6933;
          font-size: 24px;
          font-weight: 700;
        }
        .b {
          color: #ff6616;
          font-size: 15px;
          display: flex;
          .colon {
            display: inline-block;
            margin: 0 1px;
            color: #ff6933;
          }
          .block {
            display: inline-block;
            width: 16px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            background-color: #ff6933;
          }
        }
      }
      .right {
        width: 140px;
        color: #fff;
        font-weight: 700;
        height: 50px;
        line-height: 50px;
        background-color: #ff6933;
        border-radius: 50px;
      }
    }
    .bto {
      font-size: 14px;
    }
  }
  @keyframes clickPay {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  .content {
    display: flex;
    justify-content: space-around;
    margin: 20px auto;
    .left {
      width: 150px;
      text-align: center;
    }
    .right {
      width: 150px;
      text-align: center;
    }
    .titred {
      color: #d23f2b;
      font-size: 28px;
      height: 40px;
      width: 155px;
      line-height: 40px;
      font-weight: 700;
      text-align: center;
      span {
        display: inline-block;
        width: 35px;
      }
    }
    .tittext {
      font-size: 18px;
      color: #747676;
    }
    div {
      text-align: center;
    }
  }
  .btodivsa {
    width: 100%;
    height: 65px;
    margin-top: 20px;
    margin-bottom: 5px;
    background-color: #d23f2b;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (min-width: 750px) {
  .scroll-item {
    margin-top: 20px;
    margin-left: 20px;
  }
  .avatar {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .name {
    font-size: 16px;
    width: 60px;
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
  .bigDiv {
    display: flex;
    flex-direction: column;
    background-color: #ecf7e4;
    width: 750px;

    flex: 0 0 auto; /* 不缩放，保持原始大小 */
    margin: 0 auto;
    .xhf {
      width: 750px;
      overflow: hidden;

      height: 100px;
      position: fixed;
      bottom: 80px;
      background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/08/29/172492057064514357.png");
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      display: flex;
      align-items: center;
      .right {
        position: relative;
        top: 10px;
        left: 40px;
        font-size: 16px;
        span {
          color: red;
          font-weight: 700;
        }
      }
    }
    .btoDiv {
      width: 750px;
      text-align: center;
      box-sizing: border-box;
      height: 100px;
      position: fixed;
      bottom: 0;
      background-color: #fff;
      padding: 0 15px;
      justify-content: space-between;
      .top {
        display: flex;
        height: 70px;
        align-items: center;
        .left {
          text-align: left;
          flex: 1;
          .a {
            color: #ff6933;
            font-size: 24px;
            font-weight: 700;
          }
          .b {
            color: #ff6616;
            font-size: 15px;
            display: flex;
            .colon {
              display: inline-block;
              margin: 0 1px;
              color: #ff6933;
            }
            .block {
              display: inline-block;
              width: 16px;
              color: #fff;
              font-size: 14px;
              text-align: center;
              background-color: #ff6933;
            }
          }
        }
        .right {
          width: 140px;
          color: #fff;
          font-weight: 700;
          height: 50px;
          line-height: 50px;
          background-color: #ff6933;
          border-radius: 50px;
        }
      }
      .bto {
        font-size: 14px;
      }
    }
    // .btoDiv {
    //   width: 750px;
    //   text-align: center;
    //   box-sizing: border-box;
    //   height: 80px;
    //   position: fixed;
    //   bottom: 0;
    //   background-color: #cd3623;
    //   padding: 0 15px;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   .text {
    //     color: #fff;
    //     font-size: 22px;
    //     font-weight: 700;
    //   }
    //   .right {
    //     width: 160px;
    //     background-color: #fff;
    //     height: 55px;
    //     line-height: 55px;
    //     border-radius: 50px;
    //     color: #cd3724;
    //     animation: clickPay 0.8s infinite;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     flex-direction: column;
    //     .A {
    //       font-size: 12px;
    //       width: 100%;
    //       height: 12px;
    //       margin-bottom: 5px;
    //     }
    //     .B {
    //       font-size: 22px;
    //       font-weight: 700;
    //     }
    //   }
    // }
  }
}
</style>
<style scoped>
.scroll-container {
  overflow: hidden;
  height: 50px; /* 设置显示区域的高度 */
  position: relative;
}

.scroll-item {
  display: flex;
  align-items: center;
  height: 50px; /* 每个项的高度 */
  transition: all 1s ease; /* 设置平滑的动画效果 */
}

.scroll-enter-active,
.scroll-leave-active {
  transition: all 1s ease; /* 设置平滑的动画效果 */
}

.scroll-enter {
  transform: translateY(50px); /* 从下方进入 */
  opacity: 0;
}

.scroll-enter-to {
  transform: translateY(0); /* 进入后在原地 */
  opacity: 1;
}

.scroll-leave-active {
  position: absolute;
  width: 100%;
}

.scroll-leave-to {
  transform: translateY(-50px); /* 向上移出 */
  opacity: 0;
}
.van-field {
  background-color: #f8f8f8;
}
.van-checkbox {
  justify-content: center;
}
.van-count-down {
  color: #ff6933 !important;
}
</style>
